/* @keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}*/

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.spinner {
  width: 60px;
  height: 60px;
  position: relative;
}

.spinner .dot {
  position: absolute;
  inset: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.spinner .dot::after {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background-color: rgb(12, 180, 231); */
  background-color: rgb(42, 102, 151);
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner .dot {
  -webkit-animation: spin 2s infinite;
  animation: spin 2s infinite;
}

.spinner .dot:nth-child(2) {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}

.spinner .dot:nth-child(3) {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.spinner .dot:nth-child(4) {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}

.spinner .dot:nth-child(5) {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}