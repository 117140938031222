.p-invalid {
  color: red;
}

#p-invalid {
  color: red;
}

#label {
  font-weight: bold;
  font-size: medium;
  color: rgb(42, 102, 151)
}

#Mandatoryfield {
  font-weight: bold;
  font-size: medium;
  color: red;
}

.hr-text {
  line-height: 2.5em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 2.5em;
  opacity: .7;
  font-weight: 600;
  font-size: 18px;

  &:before {
    content: '';
    /* // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar */
    background: linear-gradient(to right, transparent, black, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 .5em;
    line-height: 1.5em;
    /* // this is really the only tricky part, you need to specify the background color of the container element... */
    /* color: #818078; */
    background-color: #fcfcfa;
  }
}


.hr-flight-icon {
  /* margin: 40px 0; */
  border: 0;
  height: 1px;
  overflow: visible;
  padding: 0;
  color: #000;
  text-align: center;
  background-image: linear-gradient(to right, transparent, #000, transparent);
  margin: 0.5rem 0 !important;

  &:after {
    content: "\f072";
    display: inline-block;
    position: relative;
    top: -0.4em;
    padding: 0 0.3em;
    background: white;
    font: normal normal normal 20px/1 FontAwesome;
  }
}

.actions>.p-button-rounded {
  height: 2.3rem !important;
  width: 2.3rem !important;
}

.scrollToTop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  /* border: 1px solid #dddddd; */
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.doctorviewtable{
  border: 1px solid #dddddd;
}


/* Grid style */
.trico-Grid,
.trico-Form {
  display: block;
  /* display: flex;
  flex-wrap: wrap; */
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.trico-Grid>.p-col,
.trico-Form>.p-col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.trico-Grid>.p-col>.card,
.trico-Form>.p-col>.card {
  padding: 18px;
  margin-bottom: 0;
}

.trico-Grid>.p-col>.card>h5,
.trico-Form>.p-col>.card>h5 {
  margin-top: 0;
}

.p-toolbar {
  background: #eaf0f5 !important;
  border: 1px solid #bfd1e0 !important;
  padding: 0.75rem 0.75rem !important;
  border-radius: 4px !important;
}

.p-toolbar .my-2 {
  margin: 0 !important;
  display: contents;
  align-items: center !important;
}

.p-toolbar .p-button .p-button-icon-only {
  width: 2.357rem !important;
  padding: 0.4rem 0.5rem !important;
}


/* Form style */
.p-toolbar .p-button {
  padding: 0.4rem 0.75rem !important;
  font-size: 1rem !important;
}

/* .p-toolbar > .p-toolbar-group-right > .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.6rem !important;
}

.p-toolbar > .p-toolbar-group-right > .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.36rem 0.75rem !important;
} */


/* .p-toolbar > .p-toolbar-group-right > .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.6rem !important;
}

.p-toolbar > .p-toolbar-group-right > .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-label {
  padding: 0.36rem 0.75rem !important;
} */

/* .p-toolbar .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.36rem 0.75rem !important;
} */

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.05rem 0.75rem !important;
}

.p-toolbar .p-multiselect .p-multiselect-label {
  padding: 0.4rem 0.75rem !important;
}

/* Data table row */
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  padding: 0.3rem 0.3rem !important;
  height: 2.3rem !important;
}

.showtooltip .p-tooltip-text {
  word-break: unset !important;
}

.p-fileupload>.p-fileupload-content {
  padding: 10px 14px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(42, 102, 151) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(42, 102, 151) !important;
}

.input[type="checkbox" i]:disabled {
  background: rgb(42, 102, 151) !important;
}


.flightOrder {
  overflow: hidden;
  position: relative;
  border: 1px solid rgb(42, 102, 151);
}

.flightOrder>div:first-child {
  display: flex;
  width: 50px;
  background-color: rgb(42, 102, 151);
  color: rgb(255, 255, 255);
  height: 100%;
  text-align: center;
  vertical-align: middle;
}


/* .flightOrder. { 
  width: 50px;
  background-color: rgb(42, 102, 151);
  color: rgb(255, 255, 255);
  height: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 90px;
} */

/* .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  width: auto !important;
  min-width: auto !important; 
} */

.lineHeight2 {
  line-height: 2;
}

.lineHeight1 {
  line-height: 1.5;
}

table {
  border-collapse: separate !important;
}

/* Css For Buttons  */
.p-button-outlined:enabled:hover {
  background: rgb(42, 102, 151) !important;
  color: #fff !important;
}

.p-button-outlined {
  background-color: transparent !important;
  color: rgb(42, 102, 151) !important;
  border: 1px solid !important;
}

/* For Scroll To Top Button */
.scrolltotop {
  background: rgb(42, 102, 151) !important;
  color: #fff !important;
}

/* On Hover In Imput Text */
.p-inputtext:enabled:hover {
  border-color: rgb(42, 102, 151) !important;
}

/* For Dialog Cross Icon */
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: rgb(42, 102, 151) !important;
  border: 1px solid !important;
}

/* For AutoFocus */
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem white !important;
  border-color: rgb(42, 102, 151) !important;
}

/* For Page Size Dropdown */
.p-paginator .p-dropdown {
  border-color: rgb(42, 102, 151) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: rgb(42, 102, 151) !important;
  color: white !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgb(42, 102, 151) !important;
  color: white !important;
}

/* Headers In All Screen */
.headerfont {
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
}

/* For Sorting Color */
.p-datatable .p-sortable-column.p-highlight {
  background: rgb(42, 102, 151) !important;
  color: white !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white !important;
}

/* link color for email phone number etc */
.linkcolors {
  color: #495057;
}

/* For Column Toggel */
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: rgb(42, 102, 151) !important;
  color: white !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  border-color: rgb(42, 102, 151) !important;
  box-shadow: 0 0 0 0.1rem white !important;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: rgb(42, 102, 151) !important;
}

/* For CheckBox */
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: white !important;
  background: rgb(42, 102, 151) !important;
  color: black !important;
}

/* For Selected Column In Column Toggle */
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: rgb(42, 102, 151) !important;
  color: white !important;
  border-radius: 16px;
}

/* For Headers Of Each Datatable Column */
.p-datatable .p-datatable-thead>tr>th {
  color: rgb(42, 102, 151) !important;
  font-family: arial, sans-serif;
}

/* For Dropdown List Search Input Color */
.p-dropdown .p-component .p-inputwrapper .p-inputwrapper-focus {
  color: rgb(42, 102, 151) !important;
}

/* For Dropdowns Color */
.p-dropdown .p-component .p-inputwrapper {
  border-color: rgb(42, 102, 151) !important;
  box-shadow: 0 0 0 0.1rem white !important;
  color: rgb(42, 102, 151) !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: rgb(42, 102, 151) !important;
}

.p-dropdown:not(.p-disabled).p-inputwrapper-focus {
  border-color: rgb(42, 102, 151) !important;
  box-shadow: 0 0 0 0.1rem white !important;
}

.p-dropdown .p-component .p-inputwrapper .p-focus .p-inputwrapper-filled .p-inputwrapper-focus {
  border-color: rgb(42, 102, 151) !important;
  box-shadow: 0 0 0 0.1rem white !important;
}

/* For CheckBox Hover */
.p-checkbox :not(.p-disabled):hover {
  border-color: rgb(42, 102, 151) !important;
}

.p-calendar .p-button {
  background: rgb(42, 102, 151) !important;
  border: 1px solid rgb(42, 102, 151) !important;
}

/* For Dropdown Down Icoon */
.p-dropdown .p-dropdown-trigger {
  color: rgb(42, 102, 151) !important;
}

/* For MultiSelect Down Icoon */
.p-multiselect .p-multiselect-trigger {
  color: rgb(42, 102, 151) !important;
}

/* For Dropdown Cross Icon */
.p-dropdown .p-dropdown-clear-icon {
  color: rgb(42, 102, 151) !important;
}

/*For Avatar  */
/* .p-avatar{
background-color: rgb(42, 102, 151) !important;
border-radius: none !important;
} */

.p-avatar {
  height: 36px !important;
  width: 36px !important;
  border-radius: 50% !important;
  overflow: hidden;
}

/* For Booked Ticket Attachment Icon */
.p-button-outlined-attachment {
  background-color: transparent !important;
  color: rgb(42, 102, 151) !important;
  border: none !important;
}

.p-button .p-fileupload-choose .p-component {
  background: transparent !important;
  color: rgb(42, 102, 151) !important;
}

.p-button {
  background: transparent !important;
  color: rgb(42, 102, 151) !important;
  border: 1px solid rgb(42, 102, 151);
  border-color: rgb(42, 102, 151) !important;
}

.p-button:hover {
  background: rgb(42, 102, 151) !important;
  color: #fff !important;
}

.lok{
  color: #fff !important;
}

tr:nth-child(even) {
  background-color: #fff !important;
}

.p-datepicker table td,th {
    border: none !important;
}

.p-calendar .p-button-icon-only {
  color: #fff !important;
}

.flight-actionbutton {
  width: 2.3rem !important;
  height: 2.3rem !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: rgb(42, 102, 151) !important;
    background: rgb(42, 102, 151) !important;
}

.dashboardchart-header{
font-family: "Palatino";
font-size: x-large;
font-weight: 500;
}

/* login design */
/* 
.contentArea {
  padding: 10px;
}

.welcome {
  margin-left: -2px;
  padding-bottom: 15px;
  padding-top: 10px;
  text-align: left;
}

.welcome-text {
  color: #9f989c;
  float: left;
  font-family: "Segoe UI" !important;
  font-size: 14px;
  text-align: justify;
}

.LoginBox {
  box-shadow: 0 0 15px lightgray;
  height: 470px;
  margin-top: 70px;
}

.LoginImage {
  float: left;
  padding: 9px;
  width: 42%;
}

.LoginContent {
  float: left;
  padding: 9px;
  width: 58%;
}

.notes {
  background-color: rgb(209, 209, 209);
  color: #646464;
  float: left;
  font-family: "Segoe UI";
  margin-left: 9px;
  margin-right: 10px;
  margin-top: 7px;
  padding: 10px;
  text-align: justify;
  width: 96%;
}

.Login-text {
  color: #2A6697;
  font-family: "Impact";
  font-size: 18pt;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 0;
  text-align: left;
}

.LoginForm {
  background-color: #efefef;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 229px;
  margin-top: 35px;
}

.main {
  background-color: #fff !important;
  box-shadow: 0 0 15px #666;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 1004px;
}

body {
  background: url("../images/TricoConference.jpg") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  font-family: "Segoe UI";
}

.banner {
  background-color: #2a6697;
  height: 107px;
  vertical-align: bottom !important;
}

.bannerBorder {
  height: 7px;
  background-color: #FFA800;
}

.banner h1 {
  color: #fff;
  font-family: impact;
  font-weight: normal !important;
  height: 100% !important;
  letter-spacing: 1px;
  margin-left: 15px;
  padding-top: 55px;
  text-align: left;
  margin-top: 0px;
}

input[type="text"], [type="password"] {
  border: 1px solid lightgray;
  border-radius: 5px;
  font-family: "Segoe UI";
  font-size: 14px;
  height: 25px;
  padding: 5px;
  width: 365px;
}

input[type="submit"] {
  background-color: #e1e1e1;
  border: 1px solid lightgray;
  border-radius: 3px;
  color: black;
  cursor: pointer;
  font-size: 15px !important;
  height: 35px;
  width: 110px;
}

.PersonalLogin {
  padding-left: 5px;
  padding-top: 15px;
  text-align: left;
}

.LoginForm a {
  color: #9f989c;
  font-family: "Segoe UI";
  font-size: 14px;
  text-decoration: none;
}

.forgot:hover{
  text-decoration:underline;
} */




/* krisha */
.contentArea {
  padding: 10px;
}

.welcome {
  margin-left: -2px;
  padding-bottom: 15px;
  padding-top: 10px;
  text-align: left;
}

.welcome-text {
  color: #9f989c;
  float: left;
  font-family: "Segoe UI" !important;
  font-size: 14px;
  text-align: justify;
}

.LoginBox {
  box-shadow: 0 0 15px lightgray;
  height: 470px;
  margin-top: 70px;
}

.LoginImage {
  float: left;
  padding: 9px;
  width: 42%;
}

.LoginContent {
  float: left;
  padding: 9px;
  width: 58%;
}

.notes {
  background-color: rgb(209, 209, 209);
  color: #646464;
  float: left;
  font-family: "Segoe UI";
  margin-left: 9px;
  margin-right: 10px;
  margin-top: 7px;
  padding: 10px;
  text-align: justify;
  width: 98%;
}

.Login-text {
  color: #2A6697;
  font-family: "Impact";
  font-size: 18pt;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 0;
  text-align: left;
}

.LoginForm {
  background-color: #efefef;
  /* border: 1px solid lightgray; */
  border-radius: 5px;
  height: 229px;
  margin-top: 35px; 
}

.main {
  background-color: #fff !important;
  box-shadow: 0 0 15px #666;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 1004px;
  margin-top: 15px;
}

/* body {
  background: url("../Images//background.jpg") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
  font-family: "Segoe UI";
} */

.banner {
  background-color: #2a6697;
  height: 107px;
  vertical-align: bottom !important;
}

.bannerBorder {
  height: 7px;
  background-color: #FFA800;
}

.bannermain{
  color: #fff;
  font-family: impact !important;
  font-weight: normal !important;
  height: 100% !important;
  letter-spacing: 1px;
  margin-left: 15px;
  padding-top: 55px;
  text-align: left;
  font-size: 3.1rem;
  margin-top: 0px;
}

#lblwelcome{
  font-size: x-large;
}

.PersonalLogin {
  padding-left: 5px;
  padding-top: 15px;
  text-align: left;
}

.LoginForm a {
  color: #9f989c;
  font-family: "Segoe UI";
  font-size: 14px;
  text-decoration: none;
}

.forgot:hover{
  text-decoration:underline;
}

.tabledesign td, th {
  border: none !important;
  text-align: left;
  padding: 0px;
  background: #efefef !important;
  margin-left: 10px;
}

.tabledesign td {
  padding-left: 10px;
  padding-top: 5px; 
}

.signinbutton{
  background: #dddddd !important;
  color: grey !important;
  border: none !important;
}

.signinbutton:hover{
  background: #dddddd !important;
  color: grey !important;
}

body {
  background: url("../images//loginbackground.jpg") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
}

input[type="submit"] {
  background-color: #e1e1e1;
  border: 1px solid lightgray;
  border-radius: 3px;
  color: black;
  cursor: pointer;
  font-size: 15px !important;
  height: 35px;
  width: 110px;
}


/* Responsive */
@media (max-width: 950px) {
  .main {
    width: 100%;
  }
  .LoginBox {
    width: 100%;
  }
  .LoginImage, .LoginContent {
    width: 50%;
  }
  .forgot {
    display: block !important;
  }
}

@media (max-width: 787px) {
  .LoginForm {
    margin-top: 50px !important;
  }
}


@media (max-width: 480px) {
  .bannermain {
    font-size: 0.75rem; 
  }
}

@media (max-width: 950px) {
  .bannermain {
    font-size: 2.5rem; 
  }
}

@media (max-width: 820px) {
  .bannermain {
    font-size: 1.8rem; 
  }
}

@media (max-width: 610px) {
  .bannermain {
    font-size: 1.3rem; 
  }
}
@media (max-width: 450px) {
  .bannermain {
    font-size: 1rem; 
  }
}
@media (max-width: 375px) {
  .bannermain {
    font-size: 0.8rem; 
  }
}

/* For event Closed Page */

.main-event-closed {
  background-color: #fff !important;
  box-shadow: 0 0 15px #666;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 1004px;
  /* margin-top: 15px;  */
}

.bannermain-event-closed{
  color: #fff;
  font-family: impact !important;
  font-weight: normal !important;
  height: 100% !important;
  letter-spacing: 1px;
  margin-left: 15px;
  padding-top: 55px;
  font-size: 3.1rem;
  margin-top: 0px;
}

.banner-event-closed {
  background-color: #2a6697;
  height: 107px;
  vertical-align: bottom !important;
}

.bannerBorder-event-closed {
  height: 7px;
  background-color: #FFA800;
}

.contentArea-event-closed {
  padding: 10px;
}

.welcome-text-event-closed {
  color: #9f989c;
  float: left;
  font-family: "Segoe UI" !important;
  font-size: 14px;
  text-align: justify;
}

.LoginBox-event-closed {
  box-shadow: 0 0 15px lightgray;
  height: 470px;
  margin-top: 70px;
}