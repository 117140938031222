@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  div {
    width: 210mm;
    height: 297mm;
  }
}

@font-face {
  font-family: tricoCertificateFont;  
  src: url(./../layout/static/DancingScript-Bold.ttf) format('truetype');
}

h1.bgimgH1 {
  width: 210mm;
  position: absolute;
  font-size: 40px;
  margin-top: 597px !important;
  text-align: center;
  font-family: tricoCertificateFont;
  color: black;
}
