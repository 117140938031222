.a4-container {
	left: 0.1cm;
	top: 0.1cm;
	width: 31.3cm;
	height: 43.9cm;
	/* border: 1px solid #000; */
	position: relative;
}

h1.bgimgidcardH1 {
	width: 15.45cm;
	font-weight: bolder;
	font-size: 32px;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	color: black;
	margin: 0;
}

h2.bgimgidcardH2 {
	width: 15.45cm;
	font-weight: normal;
	font-size: 25px;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	color: black;
	margin-top: 0;
}

.bgimgidcardQR{
	width: 15.45cm;
	margin-top: 40px;
}

.comma:not(:empty):not(:last-child):after {
	content: ", ";
}